import httpClient  from '../api'; 
const API_CONTROLLER = 'contract/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getOWners(contractId, code) { 
        let url = API_CONTROLLER + 'get-owners';
        return httpClient.get(url, {
            params: {
                contractId: contractId,
                code: code
            }
        });
    },

    getCode() { 
        let url = API_CONTROLLER + 'get-code';
        return httpClient.get(url);
    },

    filter(search) { 
        let url = API_CONTROLLER + 'filter';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                projectId: search.projectId,
                orderType: search.orderType,
                code: search.code,
                name: search.name,
            }
        });
    },

    getContracts(search) { 
        let url = API_CONTROLLER + 'get-contracts'
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                orderType: search.orderType,
                projectId: search.projectId,
                customerId: search.customerId,
                code: search.code,
                name: search.name,
            }
        });
    },
}